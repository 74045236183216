<template>
    <div data-scroll :data-scroll-id="name" class="terroirs-map">
        <text-wipe :header="header" id="terroirs-map-wipe" />
        <div class="main-content">
            <span class="hed-title">{{ teaser }}</span>
            <div class="content-container">
                <div class="wine-list">
                    <div
                        class="item"
                        v-for="(item, index) in wineLists"
                        :key="index"
                        :class="{ active: activeWineId === item.id }"
                    >
                        <div class="list-item-content">
                            <div class="list-navbar">
                                <div
                                    class="navbar-item"
                                    v-for="item in wineLists"
                                    :key="item.id"
                                    @click="setActiveWine(item.id)"
                                    :class="{
                                        active: activeWineId === item.id
                                    }"
                                >
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <span class="title">{{ item.title }}</span>
                                </div>
                            </div>
                            <div
                                class="item-content-container"
                                @click="changeRoute"
                            >
                                <div class="image-container">
                                    <img
                                        :src="item.image"
                                        class="item-image unselectable"
                                        alt=""
                                    />
                                </div>
                                <div
                                    :style="{ background: item.color }"
                                    class="item-content"
                                >
                                    <p
                                        text-color="white"
                                        class="header-title"
                                        underline-color="white"
                                    >
                                        {{ item.title }}
                                    </p>
                                    <!-- <header-title text-color="white" underline-color="white" :name="item.title"/> -->
                                </div>
                            </div>
                        </div>
                        <div class="map-container" id="map-container">
                            <img
                                :src="activeElement.mapImage"
                                class="map-icon"
                                alt=""
                            />
                            <span class="description">{{
                                item.description
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <img :src="background" class="background-image" alt="" />
            <div class="background-container"></div>
        </div>
        <span class="mobile-description">{{ activeElement.description }}</span>
    </div>
</template>

<script>
import textWipe from "@/components/custom/textWipe";
// import HeaderTitle from "@/components/custom/HeaderTitle";
import first from "lodash/first";
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            activeWineId: 0
        };
    },
    components: {
        textWipe
        // HeaderTitle
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        header() {
            return {
                title: this.config?.header?.title
            };
        },
        teaser() {
            return this.config?.header?.teaser;
        },
        wineLists() {
            let filterData = this.data?.list?.filter(
                (item, index) => index !== 0
            );
            return filterData?.map(item => ({
                title: item.title,
                description: item.description,
                teaser: item.teaser,
                color: item.color,
                image: first(item.image)?.devices?.[this.device?.type],
                mapImage: first(item.map_image)?.devices?.[this.device?.type],
                id: item.id,
                url: item.url
            }));
        },
        background() {
            return first(this.data?.list)?.image?.[0]?.devices?.[
                this.device?.type
            ];
        },
        activeElement() {
            return this.wineLists?.find(item => item.id === this.activeWineId);
        }
    },
    methods: {
        setActiveWine(id) {
            this.activeWineId = id;
        },
        changeRoute() {
            window.location.href = this.activeElement.url;
        }
    },
    created() {
        this.setActiveWine(first(this.wineLists)?.id);
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.terroirs-map /deep/ {
    background: #0a141c;
    padding: 144px 0 0;
    position: relative;

    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        padding: 89px 0 0;
    }
    @media only screen and (max-width: 767px) {
        padding: 55px 0 0;
    }

    .text-wipe {
        margin: 0 auto;
    }

    .main-content {
        margin-top: 34px;
        z-index: 1;
        position: relative;
        @media only screen and (max-width: 1366px) {
            margin-top: 0;
            text-align: center;
            display: block;
        }
        @media only screen and (max-width: 1023px) {
            margin-top: 21px;
        }
    }

    .hed-title {
        font-size: 34px;
        color: white;
        display: block;
        position: relative;
        z-index: 1;
        font-weight: normal;
        font-family: "Noto-Sans", "Noto-Sans-georgian" !important;
        @media only screen and (max-width: 1441px) and (min-width: 1024px) {
            font-size: 21px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            position: absolute;
            width: 100%;
            top: 34px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            position: absolute;
            width: 100%;
            top: 21px;
        }
        @media only screen and (max-width: 1023px) {
            font-size: 16px;
            padding: 0 16px;
        }
    }

    .content-container {
        display: flex;
        padding: 186px 0 186px 180px;
        z-index: 1;
        position: relative;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            padding: 133px 0 133px 130px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            padding: 112px 0 112px 93px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            padding: 27px 0 79px 47px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            padding: 38px 0 144px 47px;
        }
        @media only screen and (max-width: 767px) {
            flex-direction: column;
            padding: 46px 16px 112px;
        }
    }

    .wine-list {
        width: 100%;
        height: 600px;
        position: relative;
        -webkit-tap-highlight-color: transparent;
        @media only screen and (max-width: 1650px) and (min-width: 1366px) {
            height: 456px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            height: 440px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            height: 350px;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            max-width: 100%;
            height: 358px;
        }

        .item {
            display: flex;
            align-items: center;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            right: 0;
            opacity: 0;
            transition: opacity 0.6s $ease-out;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            @media only screen and (max-width: 767px) {
                display: block;
                left: 0;
                pointer-events: none;
            }

            &.active {
                pointer-events: all;
                opacity: 1;

                .map-icon,
                .description {
                    transition: all 0.6s 0.65s $ease-out !important;
                    opacity: 1 !important;
                    transform: translateY(0) !important;
                }
            }
        }

        .list-item-content {
            display: flex;
            align-items: center;
            height: 100%;
            top: 0;
            right: 0;
            -webkit-tap-highlight-color: transparent;
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                margin-top: 79px;
                height: fit-content;
            }
            @media only screen and (max-width: 767px) {
                display: block;
                left: 0;
            }
        }

        .item-content-container {
            height: 100%;
            margin-left: 120px;

            @media only screen and (max-width: 1440px) and (min-width: 1366px) {
                margin-left: 80px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                margin-left: 60px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                margin-left: 13px;
            }

            @media only screen and (max-width: 767px) {
                width: fit-content;
                margin: 0 auto;
            }

            .image-container {
                overflow: hidden;
                width: 450px;
                height: 480px;
                @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                    width: 350px;
                    height: 376px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    width: 270px;
                    height: 290px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    width: 183px;
                    height: 197px;
                }
                @media only screen and (max-width: 767px) {
                    width: 214px;
                    height: 230px;
                }

                .item-image {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    display: block;
                    object-fit: cover;
                }
            }

            .item-content {
                @include center-children;
                position: relative;
                width: 100%;
                height: 120px;
                z-index: 2;
                margin-top: -2px;
                @media only screen and (max-width: 1440px) and (min-width: 1366px) {
                    height: 102px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    height: 78px;
                }
                @media only screen and (max-width: 1023px) {
                    height: 52px;
                }

                .header-title {
                    font-size: 36px;
                    color: black;
                    font-weight: normal;
                    text-underline-offset: 10px;
                    @media only screen and (max-width: 1440px) and (min-width: 768px) {
                        font-size: 18px;
                        text-underline-offset: 4px;
                    }
                    @media only screen and (max-width: 767px) {
                        font-size: 21px;
                        text-underline-offset: 4px;
                    }
                }
            }
        }

        .list-navbar {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            @media only screen and (max-width: 767px) {
                flex-direction: row;
                overflow-x: auto;
                justify-content: center;
                margin-top: 0;
                margin-bottom: 55px;
            }

            .navbar-item {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                cursor: pointer;
                margin-top: 12px;

                &:first-child {
                    margin-top: 0;
                }

                &.active {
                    .title {
                        color: #e5b676;
                        @media only screen and (max-width: 767px) {
                            text-decoration: underline;
                            text-decoration-color: #e5b676;
                        }
                    }

                    .line,
                    .dot {
                        opacity: 1;
                    }
                }

                @media only screen and (max-width: 767px) {
                    margin-top: 0;
                    margin-left: 12px;
                    &:first-child {
                        margin-left: 0;
                    }
                    .line,
                    .dot {
                        display: none;
                    }
                }

                .title {
                    color: white;
                    letter-spacing: 1.4px;
                    outline: none;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                    -webkit-tap-highlight-color: transparent;
                    @media only screen and (max-width: 767px) {
                        text-decoration: underline;
                        text-underline-offset: 2px;
                        text-decoration-color: transparent;
                        white-space: nowrap;
                        padding-bottom: 20px;
                    }
                }

                .line {
                    width: 60px;
                    height: 1px;
                    background: #e5b676;
                    opacity: 0;
                    transition: opacity 0.6s $ease-out;
                    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                        width: 40px;
                    }
                    @media only screen and (max-width: 1023px) and (min-width: 768px) {
                        width: 15px;
                    }
                }

                .dot {
                    @include circle(10);
                    background: #e5b676;
                    opacity: 0;
                    margin: 0 10px;
                    transition: opacity 0.6s $ease-out;
                }
            }
        }
    }

    .map-container {
        width: 25%;
        height: 100%;
        margin-top: 10px;
        margin-left: 120px;
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
            width: 30%;
            margin-left: 80px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            margin-left: 60px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            width: 45%;
            margin-left: 25px;
        }
        @media only screen and (max-width: 767px) {
            display: none;
            margin-left: 0;
        }

        .map-icon {
            width: 419px;
            object-fit: cover;
            @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                width: 349px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                width: 319px;
            }
        }

        .description {
            color: white;
            text-align: left;
            margin-top: 25px;
            display: block;
            opacity: 0;
            transform: translateY(30px);
            transition: opacity 0.2s $ease-out, transform 0.1ms 0.6s;
            font-family: "Noto-Sans", "Noto-Sans-georgian";
            @media only screen and (max-width: 1023px) {
                display: none;
            }
        }
    }

    .background-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.15;
    }

    .background-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: linear-gradient(#0a141c 0%, rgba(10, 20, 28, 0) 100%);
    }

    .mobile-description {
        color: white;
        text-align: left;
        left: 50%;
        transform: translateX(-50%);
        max-width: 70%;
        position: absolute;
        bottom: 35px;
        display: none;
        font-family: "Noto-Sans", "Noto-Sans-georgian";
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            display: block;
            text-align: center;
        }
    }
}
</style>
